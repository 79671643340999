import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Loading from '../../../Components/Loading'
import Error from '../../../Components/Error'
import DialogX from '../../../Components/DialogX'
import AlertX from '../../../Components/AlertX'
import IconX from '../../../Components/IconX'
import InputX from '../../../Components/InputX'
import { Text, DateTime, Kludge, setHeaderTitle, formatName } from '../../../FuncX'
import Comments from './Comments'
import RequestsViewTodo from './Todo'
import { animationElementClick, animationElementLoadingStart, animationElementLoadingStop } from '../../../Components/Animations'
import API from '../../../API'

import './style.scss'
import ContentMenu from '../../../Components/ContentMenu'

const loadingStatus = [0, 0, 0]

const RequestView = ({ User }) => {

    const
        isInit = { mount: false }

    const
        { reqId } = useParams(),

        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isDialog, setDialog] = useState(false),
        [isUpdate, setUpdate] = useState(false),
        [isEdit, setEdit] = useState({ active: false }),
        [onClick___edit_active, set___onClick___edit_active] = useState(false),
        [isData, setData] = useState(false),
        [onClick___setResponsible_active, set___onClick___setResponsible_active] = useState(false),
        [onClick___setClient_active, set___onClick___setClient_active] = useState(false)

    const onChange = {

        title: (e) => {

            if (isData.title === e.target.value) {

                setEdit((prevData) => {

                    if (!prevData.description && !prevData.dateTime_limit && !prevData.todoList && !prevData.todoListItem) {

                        return {

                            active: prevData.active
                        }
                    }

                    return {

                        ...prevData,
                        title: false
                    }
                })
                return
            }
            setEdit((prevData) => {

                return {

                    ...prevData,
                    title: e.target.value
                }
            })
            return true
        },

        description: (e) => {

            if (isData.description === e.target.value) {

                setEdit((prevData) => {

                    if (!prevData.title && !prevData.dateTime_limit && !prevData.todoList && !prevData.todoListItem) {

                        return {

                            active: prevData.active
                        }
                    }

                    return {

                        ...prevData,
                        description: false
                    }
                })
                return
            }

            setEdit((prevData) => {

                return {

                    ...prevData,
                    description: e.target.value
                }
            })
            return true
        },

        dateTime_limit: (e) => {

            if (fixDateTimeLocal(isData.dateTime_limit) === e.target.value) {

                setEdit((prevData) => {

                    if (!prevData.title && !prevData.description && !prevData.todoList && !prevData.todoListItem) {

                        return {

                            active: prevData.active
                        }
                    }

                    return Kludge.deleteX(prevData, ['dateTime_limit'])
                })
                return false
            }

            setEdit((prevData) => {

                return {

                    ...prevData,
                    dateTime_limit: e.target.value.length > 0 ? e.target.value : null
                }
            })
            return true
        }
    }

    const onClick = {

        home: (e) => {

            animationElementClick(e.currentTarget)
            return true
        },

        update: async (e) => {

            if (isUpdate) return false

            setUpdate(true)
            animationElementClick(e.currentTarget)
            await getRequest()
            setUpdate(false)
            return true
        },

        copyURL: (e) => {

            animationElementClick(e.currentTarget)

            const cost = isData?.cash?.all - isData?.cash?.pay
            const input = document.createElement('input')
            const text = `Заявка #${isData?.code}${cost > 0 ? ' (К оплате ' + cost?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }) + ')' : ''}. Подробнее: https://МагТехПро.рф/lk/req/${reqId}`
            const yPosition = window.pageYOffset || document.documentElement.scrollTop

            input.style.position = 'absolute'
            input.style['left'] = '-9999px'
            input.style.fontSize = '16px'
            input.style.top = `${yPosition}px`
            input.setAttribute('readonly', '')
            document.body.appendChild(input)
            input.value = text
            input.focus()
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
            alert(`Ссылка на заявку #${isData?.code || '-'} скопирована в ваш буфер обмена!`)
            return true
        },

        edit: async (e) => {

            animationElementClick(e.currentTarget)

            if (isEdit.active) isData.description = Text.replaceAll(isData.description, '\n', '<br />')
            else isData.description = Text.replaceAll(isData.description, '<br />', '\n')

            setEdit((prevData) => {

                if (prevData.active) return { active: false }
                return { active: true }
            })

            animationElementLoadingStop(e.currentTarget)
            return true
        },

        save: async (e) => {

            if (onClick___edit_active) return false
            set___onClick___edit_active(true)
            animationElementClick(e.currentTarget)
            animationElementLoadingStart(document.querySelector('.view'))

            let response, errorOut = false, dataSave = {}

            try {

                dataSave = Kludge.deleteX(isEdit, ['active'])

                if (dataSave.description) dataSave.description = dataSave.description.replace(/<(?!img|code>|\/code>|b>|\/b>|i>|\/i>|u>|\/u>|s>|\/s>).*?>/gm, '')

                response = await API.Requests.Set(reqId, dataSave)

                if (!response.status) {

                    errorOut = response.result
                }
            } catch (error) {

                errorOut = error.message
            }

            if (!errorOut) await getRequest()
            else {

                setDialog((prevData) => {

                    return {

                        loading: false,
                        id: 'change_request_save',
                        title: 'Сохранение изменений в заявке',
                        content: <AlertX className='error' type='error' icon={<IconX name='database-x' />}>{errorOut}</AlertX>
                    }
                })
            }

            set___onClick___edit_active(false)
            setEdit(false)
            animationElementLoadingStop(document.querySelector('.view'))
            return true
        },

        changeStatus: (e) => {

            if (onClick___edit_active) return false

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'change_request_status',
                title: 'Изменение статуса заявки',
                content: <Loading module>Загрузка статусов...</Loading>
            })
            getStatuses()
            return true
        },

        selectStatus: async (e) => {

            animationElementClick(e.currentTarget)
            setTimeout(() => animationElementLoadingStart(e.currentTarget), 100)

            const cngInfo = e.target.dataset.id === '635bafbe73feaf0173f56ca0' ?
                { status: e.target.dataset.id, dateTime_completed: new Date() }
                :
                { status: e.target.dataset.id }

            const apiX = await API.Requests.Set(reqId, cngInfo)

            if (!apiX.status) {

                return setDialog((prevData) => {

                    return {

                        ...prevData,
                        loading: false,
                        content: <AlertX className='error' type='error' icon={<IconX name='server' />}>{apiX.result}</AlertX>
                    }
                })
            }

            await getRequest()
            setDialog(false)
            setEdit(false)
            return true
        },

        editResponsible: (e) => {

            animationElementClick(e.currentTarget)
            setDialog({

                loading: true,
                id: 'change_request_status',
                title: 'Изменение ответсвенного',
                content: <Loading module>Сохранение...</Loading>
            })
            Responsible.get()
            return true
        },

        editClient: (e) => {

            animationElementClick(e.currentTarget)
            setDialog({

                loading: true,
                id: 'change_request_client',
                title: 'Изменение клиента',
                content: <Loading module>Сохранение...</Loading>
            })
            Client.Get()
            return true
        }
    }

    const getRequest = async (e = null, firstLoad = false) => new Promise(async (resolve) => {

        loadingStatus[0] = 0
        loadingStatus[1] = 0
        setError({ status: false })

        if (!reqId) {

            setError({ status: true, title: 'загрузки заявки', msg: 'Неверный адрес заявки' })
            return resolve(false)
        }

        setLoading({ status: true, msg: 'Загрузка заявки...' })

        let response

        try {

            response = await API.Requests.Get(reqId, (progressEvent) => {

                loadingStatus[0] = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                return setLoading({ status: true, msg: 'Загрузка заявки...', progress: Kludge.getPercent(loadingStatus[0] + loadingStatus[1], 300) })
            })
        }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            switch (response.result) {

                case 'no_access': {

                    setError({ status: true, title: 'загрузки заявки', msg: 'У вас нет доступа к этой заявк' })
                    break
                }

                case 'no_request': case 'Request failed with status code 500': {

                    setError({ status: true, title: 'загрузки заявки', msg: 'Неверный адрес заявки' })
                    break
                }

                default: {

                    setError({ status: true, title: 'загрузки заявки', msg: response.result || 'Неизвестная ошибка' })
                    break
                }
            }

            setLoading(false)
            return resolve(false)
        }

        response.result = response.result[0]
        response.result.description = Text.replaceAll(response.result.description, '\n', '<br />')
        response.result.dateTime_create = new Date(response.result.dateTime_create)
        response.result.dateTime_limit = response.result.dateTime_limit ? new Date(response.result.dateTime_limit) : null
        response.result.dateTime_completed = response.result.dateTime_completed ? new Date(response.result.dateTime_completed) : null

        if (response.result.dateTime_limit) {

            response.result.limit_className =
                response.result.status.className === 'opened' || response.result.status.className === 'new' ?
                    response.result.dateTime_limit.getTime() < 1 ?
                        'dateTime'
                        :
                        response.result.dateTime_limit.getTime() < new Date().getTime() ?
                            'dateTime warn'
                            :
                            'dateTime ok'
                    : 'dateTime'
        } else response.result.limit_className = 'dateTime'

        setData(response.result)
        setHeaderTitle(`Заявка #${response.result?.code}`)
        setLoading(false)
        return resolve(true)
    })

    const getStatuses = async () => {

        const apiX = await API.Requests.GetStatuses()

        if (!apiX.status) {

            return setDialog((prevData) => {

                return {

                    ...prevData,
                    loading: false,
                    content: <AlertX className='error' type='error' icon={<IconX name='server' />}>{apiX.result}</AlertX>
                }
            })
        }

        return setDialog((prevData) => {

            return {

                ...prevData,
                loading: false,
                content: <>
                    {apiX.result.map((item) => (
                        <>
                            {String(item._id) !== String(isData.status._id) &&
                                <div key={item._id} data-id={item._id} className={`btn_default ${item.className}`} onClick={onClick.selectStatus}><IconX name={item.icon} /> {item.name}</div>
                            }
                        </>
                    ))}
                </>
            }
        })
    }


    const Responsible = {

        get: async () => {

            const apiX = await API.Users.GetResponsibles()

            if (!apiX.status) {

                setDialog((prevData) => {

                    return {

                        ...prevData,
                        loading: false,
                        content: <AlertX className='error' type='error' icon={<IconX name='server' />}>{apiX.result.response.data.message}</AlertX>
                    }
                })
                return false
            }

            setDialog((prevData) => {

                return {
                    ...prevData,
                    loading: false,
                    content: <>
                        {apiX.result.map((item, index) => (
                            <>
                                {item?._id !== isData?.responsible?._id &&
                                    <div key={index} data-id={item._id} className='btn_default user' onClick={Responsible.set}><IconX name='person' /> {item.name.last} {item.name.first} {item.name.middle}</div>
                                }
                            </>
                        ))}
                    </>
                }
            })
            return true
        },

        set: async (e) => {

            if (onClick___setResponsible_active) return false
            set___onClick___setResponsible_active(true)
            animationElementClick(e.currentTarget)
            setTimeout(() => animationElementLoadingStart(e.currentTarget), 100)

            const apiX = await API.Requests.Set(reqId, { responsible: e.target.dataset.id })

            if (!apiX.status) {

                setDialog((prevData) => {

                    return {

                        ...prevData,
                        loading: false,
                        content: <AlertX className='error' type='error' icon={<IconX name='server' />}>{apiX.result.response.data.message}</AlertX>
                    }
                })
                return false
            }

            set___onClick___setResponsible_active(false)
            await getRequest()
            setDialog(false)
            setEdit(false)
            return true
        }
    }

    const Client = {

        type: 'individuals',
        select: { corp: null, client: null },
        data: null,

        Get: async () => {

            const apiX = await API.Users.GetClients()

            if (!apiX.status) {

                setDialog((prevData) => {

                    return {

                        ...prevData,
                        loading: false,
                        content: <AlertX className='error' type='error' icon={<IconX name='server' />}>{apiX.result.response.data.message}</AlertX>
                    }
                })
                return false
            }

            setDialog((prevData) => {

                return {

                    ...prevData,
                    loading: false
                }
            })

            Client.data = apiX.result
            Client.ReRender()
            return true
        },

        GetIndividuals: () => <>
            {Client?.data?.individuals?.map((item, index) => (
                <>
                    <div key={index} data-id={item?._id} data-type="individuals" className='btn_default user' onClick={Client.Set}><IconX name='person' /> {item?.name?.last} {item?.name?.first} {item?.name?.middle}</div>
                </>
            ))}
        </>,

        GetCorps: () => <>
            {Client?.data?.corps?.map((item, index) => (
                <>
                    <div key={index} data-id={item?._id} data-name={item?.name} data-type="corps" className='btn_default user' onClick={Client.SetCorp}><IconX name='building' /> {item?.name}</div>
                </>
            ))}
        </>,

        GetUserOfCorps: () => <>
            <div className='btn_default corpBack' onClick={(_) => {

                Client.select.corp = null
                Client.type = 'corps'
                Client.ReRender()
            }}><IconX name='arrow-left' /> Вернуться</div>
            <div className='corpTitle'>{Client?.select?.corpName}</div>
            {Client?.data?.individuals?.map((individual, index) => (
                <>
                    {individual?.corps.map((item2, index2) => (
                        <>
                            {item2?._id === Client?.select?.corp &&
                                <div
                                    key={index} data-id={individual?._id}
                                    data-type="individuals"
                                    className='btn_default user'
                                    onClick={Client.Set}
                                ><IconX name='person' /> {individual?.name?.last} {individual?.name?.first} {individual?.name?.middle}</div>
                            }
                        </>
                    ))}
                </>
            ))}
        </>,

        SetList: (e, type) => {

            if (Client.type === type || Client.type === type + 'Select') return false

            animationElementClick(e.currentTarget)

            Client.type = type
            Client.ReRender()
            return true
        },

        ReRender: () => {

            setDialog((prevData) => {

                return {

                    ...prevData,
                    content: <>
                        <div className='types'>
                            <div className={`type${Client.type === 'individuals' ? ' active' : ''}`} onClick={(e) => Client.SetList(e, 'individuals')}><IconX name='person' />Физ. Лица</div>
                            <div className={`type${Client.type === 'corps' || Client.type === 'corpsSelect' ? ' active' : ''}`} onClick={(e) => Client.SetList(e, 'corps')}><IconX name='building' />Юр. Лица</div>
                        </div>
                        <div className="list">
                            {Client.type === 'individuals' && <>{Client.GetIndividuals()}</>}
                            {Client.type === 'corps' && <>{Client.GetCorps()}</>}
                            {Client.type === 'corpsSelect' && Client?.select?.corp !== null && <>{Client.GetUserOfCorps()}</>}
                        </div>
                    </>
                }
            })
        },

        SetCorp: (e) => {

            animationElementClick(e.currentTarget)

            Client.select.corp = e.target.dataset.id
            Client.select.corpName = e.target.dataset.name
            Client.type = 'corpsSelect'
            Client.ReRender()
        },

        Set: async (e) => {

            if (onClick___setClient_active) return false
            set___onClick___setClient_active(true)
            animationElementClick(e.currentTarget)
            setTimeout(() => animationElementLoadingStart(e.currentTarget), 100)

            Client.select.client = e.target.dataset.id

            const setData = Kludge.deleteX(Client.select, ['corpName'])

            const apiX = await API.Requests.Set(reqId, { ...setData })

            if (!apiX.status) {

                setDialog((prevData) => {

                    return {

                        ...prevData,
                        loading: false,
                        content: <AlertX className='error' type='error' icon={<IconX name='server' />}>{apiX.result.response.data.message}</AlertX>
                    }
                })
                return false
            }

            set___onClick___setClient_active(false)
            await getRequest()
            setDialog(false)
            setEdit(false)
            return true
        }
    }

    const checkPermissionEdit = (typeList = [], edit = false, newRequest = false, closeRequest = false) => {

        if (!User) return false
        if (!isData) return false

        for (const type of typeList) {

            switch (type) {

                case 'admin': {

                    if (closeRequest && isData.status.className === 'closed') return false
                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true

                    break
                }

                case 'author': {

                    if (closeRequest && isData.status.className === 'closed') return false
                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true
                    if (edit) {

                        if (User._id === isData.author._id && (newRequest && isData.status.className === 'new')) return true
                    }
                    else if (User._id === isData.author._id) return true

                    break
                }

                case 'client': {

                    if (closeRequest && isData.status.className === 'closed') return false
                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true
                    if (edit) {

                        if (User._id === isData.client._id && (newRequest && isData.status.className === 'new')) return true

                    } else if (User._id === isData.client._id) return true

                    break
                }

                default: { }
            }
        }

        return false
    }

    const fixDateTimeLocal = (dateTime) => {

        if (dateTime) {

            const dateTimeFix = DateTime.fix(dateTime)

            return `${dateTimeFix.y}-${dateTimeFix.m}-${dateTimeFix.d}T${dateTimeFix.H}:${dateTimeFix.M}`
        }

        return ''
    }

    const Mount = () => {

        isInit.mount = true
        getRequest(null, true)
        setHeaderTitle('Загрузка...')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>

        {isError.status &&
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`МТП | Личный кабинет | Заявки | ${isError.msg}`}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        }

        {isData &&
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`МТП | Личный кабинет | Заявки | Заявка #${isData.code}`}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        }

        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                {isDialog.content}
            </DialogX>
        }

        <div id="View">
            <ContentMenu>
                <Link to='/lk/req'><IconX name='chevron-left' />Заявки</Link>

                {!isLoading.status && !isError.status && isData && !isEdit.active &&
                    <div className={isUpdate ? 'elementLoading' : ''} onClick={onClick.update}><IconX name="arrow-clockwise" />Обновить</div>
                }

                {!isLoading.status && !isError.status && isData &&
                    <div className={isEdit.active ? 'btn active' : 'btn'} onClick={onClick.edit}>{isEdit.active ? <><IconX name='pencil-fill' />Завершить редактирование</> : <><IconX name='pencil' />Редактировать</>}</div>
                }

                {!isLoading.status && !isError.status
                    && isData
                    && isEdit.active
                    && (isEdit?.title?.length > 0
                        || isEdit?.description?.length > 0
                        || isEdit.hasOwnProperty('dateTime_limit')
                        || isEdit.hasOwnProperty('todoList')
                        || isEdit.hasOwnProperty('todoListItem')
                    ) && <div className='btn save' onClick={onClick.save}><IconX name='floppy' />Сохранить</div>
                }

                {isData && !isEdit.active &&
                    <>
                        <Link to={`/lk/req/${reqId}/history`}><IconX name='clock-history' />История изменений</Link>
                        <Link to={`/lk/req/${reqId}/works`}><IconX name='cart' />Список работ</Link>
                    </>
                }

                {!isLoading.status && !isError.status && isData && !isEdit.active &&
                    <div onClick={onClick.copyURL}><IconX name='copy' />Копировать адрес заявки</div>
                }
            </ContentMenu>

            {isLoading.status ?
                <Loading module progress={isLoading.progress} >{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {isData &&
                                <div className='view'>
                                    <div className='info'>
                                        <div className={`status ${isData.status.className} ${isEdit.active && checkPermissionEdit(['admin']) ? 'edit' : ''}`}>
                                            {isEdit.active && checkPermissionEdit(['admin']) && <div className="edit"><IconX name="pencil-square" onClick={onClick.changeStatus} /></div>}
                                            <IconX name={isData.status.icon} /> {isData.status.name}
                                        </div>

                                        {isEdit.active && checkPermissionEdit(['author', 'client'], true, true, true) ?
                                            <InputX name="title" label="Название заявки" value={isData.title} handle={onChange.title} required={true} />
                                            :
                                            <div className='title'>{isData.title}</div>
                                        }

                                        {isEdit.active && checkPermissionEdit(['author', 'client'], true, true, true) ?
                                            <InputX textareaHTML name="description" label="Описание" value={isData.description} handle={onChange.description} required={true} />
                                            :
                                            <div className='description' dangerouslySetInnerHTML={{ __html: isData.description }}></div>
                                        }

                                        <RequestsViewTodo User={User} reqId={reqId} isDataView={isData} isEditView={isEdit} />

                                        <div className="sub">
                                            <div className="dateTime">
                                                <IconX name="plus-lg" /> <b>Дата создания:&nbsp;</b>{DateTime.parceDateTime(isData.dateTime_create)}
                                            </div>
                                            <div className={
                                                `${isEdit.active
                                                    && ((checkPermissionEdit(['author', 'client'], true, true, false)) || checkPermissionEdit(['admin'], false, false, true))
                                                    && isData.status.className !== 'closed' ? isData.limit_className + ' edit' : isData.limit_className
                                                }`
                                            }>
                                                {isEdit.active
                                                    && ((checkPermissionEdit(['author', 'client'], true, true, true)) || checkPermissionEdit(['admin'], false, false, true)) ?
                                                    <InputX name="dateTime_limit" type='datetime-local' label="Дедлайн" handle={onChange.dateTime_limit} value={fixDateTimeLocal(isData.dateTime_limit)} />
                                                    :
                                                    <><IconX name="fire" /> <b>Дата дедлайна:&nbsp;</b>{isData?.dateTime_limit?.getTime() ? DateTime.parceDateTime(isData.dateTime_limit) : "Срок не указан"}</>
                                                }
                                            </div>
                                            <div className="dateTime">
                                                <IconX name="check-all" /> <b>Дата завершения:&nbsp;</b> {isData?.dateTime_completed?.getTime() > 0 ? DateTime.parceDateTime(isData.dateTime_completed) : "Заявка не завершена"}
                                            </div><br />
                                            <div className="members">
                                                <IconX name="person-fill-add" /><b>Автор:&nbsp;</b> {formatName(isData?.author)}
                                            </div>
                                            <div className="members">
                                                {isEdit.active && checkPermissionEdit(['admin'], false, false, true) && <div className='edit' onClick={onClick.editResponsible}><IconX name="pencil-square" /></div>}
                                                <IconX name="person-fill-gear" /> <b>Ответственный:&nbsp;</b>{isData.responsible ?
                                                    formatName(isData?.responsible)
                                                    :
                                                    <>Не назначен</>
                                                }
                                            </div>
                                            <div className="members">
                                                {isEdit.active && checkPermissionEdit(['admin'], false, false, true) && <div className='edit' onClick={onClick.editClient}><IconX name="pencil-square" /></div>}
                                                <IconX name="person-fill-check" /> <b>Клиент:&nbsp;</b> {isData.corp &&
                                                    <>
                                                        <a href={`/lk/corp/${isData?.corp?._id}`} target='_blank' rel='noreferrer'>{isData.corp.name} <IconX name="box-arrow-up-right" /></a> →&nbsp;
                                                    </>
                                                }
                                                {formatName(isData?.client)}
                                            </div>

                                            {isData?.subs?.length > 0 &&
                                                <div className="members">
                                                    <IconX name="star-fill" />
                                                    <b>Подписаны:</b>
                                                    <div className="subs">
                                                        {isData?.subs?.map((sub) => (<div className='sub'>{formatName(sub)}</div>))}
                                                    </div>
                                                </div>
                                            }

                                            {isData?.cash?.all || isData?.cash?.pay ?
                                                <>
                                                    <div className="cost">
                                                        <IconX name="cash" />
                                                        <ul>
                                                            <li className={isData?.cash?.work > 0 ? 'white' : ''}><b>Сумма всех работ:</b>&nbsp;{isData?.cash?.work?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</li>
                                                            {isData?.cash?.spen > 0 &&
                                                                <li className="white"><b>Расходы:</b>&nbsp;{isData?.cash?.spen?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</li>
                                                            }

                                                            <li className={isData?.cash?.pay > 0 ? 'green' : ''}><b>Оплачено:</b>&nbsp;{isData?.cash?.pay?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</li>
                                                            {(isData?.cash?.all - isData?.cash?.pay) > 0 ?
                                                                <li className="red"><b>К оплате:</b>&nbsp;{(isData?.cash?.all - isData?.cash?.pay)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</li>
                                                                :
                                                                <>
                                                                    {(isData?.cash?.pay - isData?.cash?.all) > 0 &&
                                                                        <li className="yellow"><b>Сверхоплата:</b>&nbsp;{((isData?.cash?.all - isData?.cash?.pay) * -1)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</li>
                                                                    }
                                                                </>
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                    <Comments requestId={reqId} requestStatus={isData.status.className} requestEdit={isEdit.active} />
                                </div>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default RequestView