import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { checkUserStatus, DateTime, formatName, setHeaderTitle } from '../../FuncX'
import Loading from '../../Components/Loading'
import Error from '../../Components/Error'
import IconX from '../../Components/IconX'
import Block from '../../Components/Block'
import API from '../../API'

import './style.scss'
import ContentMenu from '../../Components/ContentMenu'
import { animationElementClick } from '../../Components/Animations'
import InputX from '../../Components/InputX'


const Profile = ({ User }) => {

    const isInit = { mount: false }

    const
        { userId } = useParams(),

        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '' }),
        [isData, setData] = useState(false),
        [isEdit, setEdit] = useState({ status: false })

    const onClick = {

        edit: (e) => {

            animationElementClick(e.currentTarget)

            setEdit((prevData) => {

                if (prevData.status) return { status: false }
                return { status: true }
            })
        },

        save: (e) => {

            animationElementClick(e.currentTarget)

        }
    }

    const onChange = {

        
    }

    const parceStatus = (status) => {

        switch (status) {

            case 'gAdmin': {

                status = 'Администратор'
                break
            }

            case 'moder': {

                status = 'Модератор'
                break
            }

            case 'client': {

                status = 'Клиент'
                break
            }

            default: status = 'Неизвестно'
        }

        return status
    }

    const update = async () => {

        setError({ status: false })
        setLoading({ status: true, msg: 'Получение пользователя...' })

        let response

        try { response = await API.Users.Get(userId) }
        catch (error) {

            setError({ status: true, title: 'получения пользователя!', msg: error.message })
            setLoading({ status: false })
            return false
        }

        if (!response.status) {

            setError({ status: true, title: 'получения пользователя!', msg: response.result })
            setLoading({ status: false })
            return false
        }

        setData(response.result)
        setHeaderTitle(`Профиль > ${formatName(response.result)}`)
        return setLoading({ status: false })
    }

    const Mount = () => {

        isInit.mount = true
        update()
        setHeaderTitle('Загружается...')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`МТП | Профиль пользователя`}</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id='Profile'>
            {isLoading.status ? <Loading module>{isLoading.msg}</Loading> : isError.status ? <Error title={isError.title}>{isError.msg}</Error> :
                <>
                    <ContentMenu>
                        <div onClick={(_) => window.close()}>Закрыть</div>
                        {/* {checkUserStatus(['gAdmin'], User?.status) && <div onClick={onClick.edit}>Изменить</div>} */}

                        {!isLoading.status && !isError.status && isData && checkUserStatus(['gAdmin'], User?.status) &&
                            <div className={isEdit.status ? 'btn active' : 'btn'} onClick={onClick.edit}><IconX name='pencil' />Редактировать</div>
                        }

                        {!isLoading.status && !isError.status && isData && isEdit.status && Object.keys(isEdit).length > 1 &&
                            <div className='btn save' onClick={onClick.save}><IconX name='floppy' />Сохранить</div>
                        }
                    </ContentMenu>
                    {!isEdit?.status ?
                        <Block name="PersonalInfo" icon={<IconX name="person-lines-fill" />} title={formatName(isData, false, false)}>
                            <div className="container">
                                <div className="avatar"><img src={API.Files.GetPrivate(isData?.avatar)} alt='avatar user' /></div>
                                <div className="info">
                                    <div>{isData?.active ? <span className="active">Активен</span> : <span className="inActive">Отключен</span>}</div>
                                    <div><b>Статус:</b> {parceStatus(isData?.status)}</div>
                                    <div><b>Создан:</b> {isData?.dateTime_create ? DateTime.fix(new Date(isData?.dateTime_create)).text : 'Неизвестно'}</div>
                                    <div><b>Последний визит:</b> {isData?.dateTime_last ? DateTime.fix(new Date(isData?.dateTime_last)).text : 'Неизвестно'}</div>
                                    {(checkUserStatus(['gAdmin', 'moder'], User?.status) || User._id === userId) &&
                                        <>
                                            <div><b>Номер телефона:</b> <a className="brand" href={`tel:+${isData?.phoneNumber}`}>+{isData?.phoneNumber}</a></div>
                                            <div><b>E-Mail:</b> {isData?.email ? <a className="brand" href={`mailto:${isData?.email}`}>{isData?.email}</a> : 'Неизвестно'}</div>
                                        </>
                                    }
                                </div>
                            </div>

                            {(checkUserStatus(['gAdmin', 'moder'], User?.status) || User._id === userId) && isData?.corps && isData?.corps?.length > 0 &&
                                <details>
                                    <summary>Организации</summary>
                                    {isData.corps.map((item, idx) => (
                                        <div key={item._id} className="info">
                                            {idx + 1}. <Link className="brand" href={`/lk/corp/${item._id}`} target="_blank" rel="noreferrer">{item.name} → {item.position} <IconX name="box-arrow-up-right" /></Link>
                                        </div>
                                    ))}
                                </details>
                            }
                        </Block>
                        :
                        <div className="contentEdit">
                            <InputX checkbox name="active" label="Доступ к личному кабинету" value={isData?.active} />
                            <InputX name="nameLast" label="Фамилия" value={isData?.name?.last} />
                            <InputX name="nameFirst" label="Имя" value={isData?.name?.first} />
                            <InputX name="nameMiddle" label="Отчество" value={isData?.name?.middle} />
                            <InputX type="number" name="phoneNumber" label="Номер телефона" value={isData?.phoneNumber} />
                            <InputX name="email" label="Электронная почта" value={isData?.email} />
                            <details>
                                <summary>Организации</summary>
                                {isData.corps.map((item, idx) => (
                                    <div key={item._id} className="info">
                                        {idx + 1}. <Link className="brand" href={`/lk/corp/${item._id}`} target="_blank" rel="noreferrer">{item.name} → {item.position} <IconX name="box-arrow-up-right" /></Link>
                                    </div>
                                ))}
                            </details>
                        </div>
                    }
                </>
            }
        </div>
    </>)
}

export default Profile