import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import Loading from '../../Components/Loading'
import Error from '../../Components/Error'
import IconX from '../../Components/IconX'
import InputX from '../../Components/InputX'
import ContentMenu from '../../Components/ContentMenu'
import { DateTime, formatName, parceDeviceTypeClient, parceMessageFormatedSync, setHeaderTitle } from '../../FuncX'
import API from '../../API'

import './style.scss'



const Dialogs = ({ User }) => {

    const
        _def = { isInit: { mount: false, loading: false }, isTimer: { update: { _: null, active: false, time: 60000 } } },
        _ = _def

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isData, setData] = useState([])

    const update = (stealth = true, retry = true) => new Promise(async (resolve) => {

        if (isLoading.status) return resolve(false)
        if (!stealth) {

            setError({ status: false })
            setLoading({ status: true, msg: 'Загрузка диалогов...' })
        }

        let response

        try { response = await API.Dialogs.Get() }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            if (!stealth) {

                setError({ status: true, title: 'загрузки диалогов', msg: response.result })
                setLoading({ status: false })
            }

            if (retry) _.isTimer.update._ = setTimeout(update, _.isTimer.update.time)

            return resolve(false)
        }

        if (retry) _.isTimer.update._ = setTimeout(update, _.isTimer.update.time)

        setData(response.result)
        setLoading({ status: false })
        return resolve(true)
    })

    const parceContent = (ctt) => {

        switch (ctt?.type || '') {

            case 'image': return '[ИЗОБРАЖЕНИЕ]'
            case 'ptt': return '[АУДИО]'
            case 'text':
            case 'chat': {

                const messageFormated = parceMessageFormatedSync([ctt])

                return messageFormated?.[0]?.content?.text
            }
            default: return <></>
        }
    }

    const parceDateTimeAutoClose = (dateTime) => {

        const tmp = dateTime.getTime() - new Date().getTime()

        return <span className={`${tmp < (86400 * 1000) ? 'red' : 'green'}`}>До авто закрытия {DateTime.msToTime(tmp, false).text}</span>
    }

    const Mount = () => {

        _.isInit.mount = true
        setHeaderTitle('Обращения')
        update(false)

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        clearTimeout(_.isTimer.update._)
        _.isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Обращения</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="Dialogs">
            <ContentMenu>
                <Link to="/lk/dialogs/create"><IconX name="plus-lg" />Создать</Link>
                {!isLoading?.status && <div className="info">Открытых: {isData?.filter(({ open }) => open === true)?.length}</div>}
                {/* <InputX label="Поиск..." /> */}
            </ContentMenu>

            {isLoading?.status ? <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading> : isError.status ? <Error title={isError.title} msg={isError.msg} /> :
                <>
                    {isData?.map(({ _id, dateTime_last, dateTime_close, open, user, lastMessage, unReadCount }) => (
                        <Link key={_id} className={`dialog ${open ? 'open' : 'close'}`} to={`/lk/dialogs/${_id}`}>
                            <div className="head">
                                {unReadCount > 0 && <div className='unReadCount'>{unReadCount}</div>}
                                <img className="avatar" src={API.Files.GetPrivate(user?.avatar)} alt={`avatar user by ${user?._id}`} />
                                <div className="user">{formatName(user, false, false)}</div>
                            </div>
                            <div className="info">
                                {open ? <div>{dateTime_close ? parceDateTimeAutoClose(new Date(dateTime_close)): <span className="red">Нет ответа</span>}</div> : <div>Закрыто</div>}
                                <div><b>От даты: </b>{DateTime.fix(new Date(dateTime_last)).text}</div>
                                <div><b>От кого: </b>{lastMessage?.system ? 'Система' : formatName(lastMessage?.from, false, false)}</div>
                                <div className="content">
                                    <div className={`left${lastMessage?.read?.status ? '' : ' unRead'}`}>
                                        {parceDeviceTypeClient(lastMessage?.device)}
                                        <IconX name={lastMessage?.social} />
                                    </div>
                                    <div className="right">{parceContent(lastMessage)}</div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </>
            }
        </div >
    </>)
}

export default Dialogs