import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { checkUserStatus, DateTime, formatName, setHeaderTitle } from '../../../../FuncX'
import AlertX from '../../../../Components/AlertX'
import IconX from '../../../../Components/IconX'
import Loading from '../../../../Components/Loading'
import Error from '../../../../Components/Error'
import ContentMenu from '../../../../Components/ContentMenu'
import API from '../../../../API'

import './style.scss'

const
        _def = { isInit: { mount: false, loading: false }, isAfterLoading: { skip: 0, count: 0 } },
        _ = _def

const RequestsViewHistory = ({ User }) => {

    const
        { reqId } = useParams(),

        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isLoadingNext, setLoadingNext] = useState({ status: false, msg: '', progress: 0 }),
        [isRequest, setRequest] = useState(null),
        [isHistory, setHistory] = useState([]),
        [isHistoryCount, setHistoryCount] = useState(0)

    const getHistorys = (next = false) => new Promise(async (resolve) => {

        if (_.isAfterLoading.skip > 0 && _.isAfterLoading.skip >= _.isAfterLoading.count) return false
        if (_.isInit.loading) return false

        _.isInit.loading = true
        setError(false)

        if (!next) setLoading({ status: true, msg: 'Загрузка истории заявки...' })
        else {

            setLoadingNext({ status: true, msg: 'Загрузка истории заявки...' })
        }

        if (!reqId) {

            setError({ status: true, title: 'загрузки истории заявки', msg: 'Неверная заявка' })
            setLoading(false)
            setLoadingNext(false)
            _.isInit.loading = false
            return resolve(false)
        }

        const onDownloadProgress = (progressEvent) => {

            if (next) return false

            if (!_.isInit.loading) return false

            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

            return setLoading({ status: true, msg: 'Загрузка истории заявки...', progress: percentage })
        }

        let response = null

        try { response = await API.Requests.GetHistory(reqId, _.isAfterLoading.skip, onDownloadProgress) }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            setError({ status: true, title: 'загрузки истории.', msg: response.result })
            setLoading(false)
            setLoadingNext(false)
            _.isInit.loading = false
            return false
        }

        setHistoryCount(response.result.count)
        _.isAfterLoading.count = response.result.count

        if (!next) {

            _.isAfterLoading.skip = response.result.history.length
            setHistory(response.result.history)
            setLoading(false)
        } else {

            _.isAfterLoading.skip += response.result.history.length

            if (_.isAfterLoading.skip >= _.isAfterLoading.count) _.isAfterLoading.skip = _.isAfterLoading.count

            setHistory((prevData) => [...prevData, ...response.result.history])
            setLoadingNext(false)
        }

        _.isInit.loading = false
        return resolve(true)
    })

    const getData = () => new Promise(async (resolve) => {

        setError(false)
        setLoading({ status: true, msg: 'Загрузка заявки...' })

        if (!reqId) {

            setError({ status: true, title: 'загрузки заявки', msg: 'Неверная заявка' })
            setLoading({ status: false })
            return resolve(false)
        }

        let res

        try {

            res = await API.Requests.Get(reqId, (progressEvent) => {

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                return setLoading({ status: true, msg: 'Загрузка заявки...', progress: percentage })
            })
        } catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            setError({ status: true, title: 'загрузки заявки', msg: res.result })
            setLoading({ status: false })
            return resolve(false)
        }

        res.result = res.result?.[0] || null

        setRequest(res.result)
        setHeaderTitle(`Заявка #${res.result?.code} > История изменений`)

        return resolve(await getHistorys())
    })

    const parceHistory = (history) => {

        const tmo_history = []
        for (const a of Object.keys(history)) {

            if (a === 'title') tmo_history.push(<><div className="event">Изменено название</div><div>{history[a]}</div></>)
            if (a === 'description') tmo_history.push(<><div className="event">Изменено описание</div><div dangerouslySetInnerHTML={{ __html: history[a] }}></div></>)
            if (a === 'status') tmo_history.push(<><div className="event">Изменён статус</div><div>{history[a].name}</div></>)
            if (a === 'dateTime_limit') tmo_history.push(<><div className="event">Изменён дедлайн</div><div>{DateTime.fix(new Date(history[a])).text}</div></>)
            if (a === 'client') {

                if (history?.corp?._id) {

                    tmo_history.push(<>
                        <div className="event">Изменён клиент</div>
                        <a
                            className='brand'
                            href={`/lk/corp/${history?.corp?._id}`}
                            target='_blank'
                            rel="noreferrer"
                        >{history?.corp?.name} <IconX name="box-arrow-up-right" /></a>
                        &nbsp;→&nbsp;
                        {formatName(history[a])}
                    </>)
                } else tmo_history.push(<>
                    <div className="event">Изменён клиент</div>
                    {formatName(history[a])}
                </>)
            }
            if (a === 'responsible') tmo_history.push(<>
                <div className="event">Изменён ответсвенный</div>
                {formatName(history[a])}
            </>)
            if (a === 'todoListItem_status') tmo_history.push(<>
                <div className="event">Изменён статус задачи в списке: <b>{history[a].todoListName}</b></div>
                {history[a].name}: <b>{history[a].status ? 'Выполнена' : 'Не выполнена'}</b>
            </>)
            if (a === 'todoList_remove') tmo_history.push(<>
                <div className="event">Удален список задач</div>
                {history[a]}
            </>)
            if (a === 'todoListItem_remove') tmo_history.push(<>
                <div className="event">Удалена задача из списка: <b>{history[a].todoListName}</b></div>
                {history[a].name}
            </>)
            if (a === 'todoList_add') tmo_history.push(<>
                <div className="event">Добавлен список задач</div>
                {history[a]}
            </>)
            if (a === 'todoListItem_add') tmo_history.push(<>
                <div className="event">Добавлена задача в список: <b>{history[a].todoListName}</b></div>
                {history[a].name}
            </>)
            if (a === 'todoList_edit') tmo_history.push(<>
                <div className="event">Изменено название списка</div>
                <div>Старое: <b>{history[a].oldName}</b></div>
                <div>Новое: <b>{history[a].newName}</b></div>
            </>)
            if (a === 'todoListItem_edit') tmo_history.push(<>
                <div className="event">Изменено название задачи в списке: <b>{history[a].todoListName}</b></div>
                <div>Старое: <b>{history[a].oldName}</b></div>
                <div>Новое: <b>{history[a].newName}</b></div>
            </>)
            if (a === 'work_add') tmo_history.push(<>
                <div className="event">Добавлена работа</div>
                <div>Наименование: <b>{history[a].name}</b></div>
                {history[a].count > 0 && <div>Колличество: <b>{history[a].count || 1}шт.</b></div>}
                {history[a].time > 0 && <div>Колличество: <b>{history[a].time || 1}ч.</b></div>}
                <div>Сумма: <b>{(history[a].cost * (history[a]?.count > 0 ? history[a]?.count : history[a].time > 0 ? history[a].time : 1)).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</b></div>
                {history[a].comment && <div>Комментарий: <b>{history[a].comment}</b></div>}
            </>)
            if (a === 'work_update') tmo_history.push(<>
                <div className="event">Изменена работа</div>
                <div><b>Наименование: </b>{history[a].name}</div>
                {history[a].count > 0 && <div><b>Колличество: </b>{history[a].count || 1}шт.</div>}
                {history[a].time > 0 && <div><b>Колличество: </b>{history[a].time || 1}ч.</div>}
                {history[a].cost > 0 && <div><b>Цена:</b> {history[a].cost.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>}
                <div><b>Сумма: </b>{(history[a].cost * (history[a]?.count > 0 ? history[a]?.count : history[a].time > 0 ? history[a].time : 1)).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                {history[a].comment && <div>Комментарий: <b>{history[a].comment}</b></div>}
            </>)
            if (a === 'work_remove') tmo_history.push(<>
                <div className="event">Удалена работа</div>
                <div>{history[a].name} ({history[a].cost.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })})</div>
            </>)
        }

        return <>
            {tmo_history.map((history, idx) => (
                <div key={idx} className="type">{history}</div>
            ))}
        </>
    }

    const handleScroll = async (e) => {

        if (_.isInit.loading) return false

        const scrollBottom = Math.round(e.target.scrollTop + e.target.offsetHeight) >= e.target.scrollHeight - 1500

        if (scrollBottom) await getHistorys(true)

        return true
    }

    const Mount = () => {

        _.isInit.mount = true
        getData()
        setHeaderTitle('Загрузка...')
        document.querySelector('.content.lk').addEventListener('scroll', handleScroll)

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        document.querySelector('.content.lk').removeEventListener("scroll", handleScroll)
        _.isInit = _def.isInit
        _.isAfterLoading = _def.isAfterLoading
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <div id="RequestsViewHistorys">
            <ContentMenu>
                <Link to={`/lk/req/${reqId}`}><IconX name='chevron-left' />К заявке</Link>
                {isHistoryCount > 0 && <div className="info">Колличество изменений: {isHistoryCount}</div>}
            </ContentMenu>

            {isLoading.status ?
                <Loading module progress={isLoading.progress} >{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {isHistory?.length > 0 ?
                                <>

                                    {isHistory.map((history, index) => (
                                        <div key={history?._id} className="item">
                                            <div className="head">
                                                <div className='info'>
                                                    <div className='name'>
                                                        <b>Автор: </b>{formatName(history?.author)}
                                                    </div>
                                                    <div className='dateTime_Create'>
                                                        <b>Дата создания: </b>{DateTime.fix(new Date(history?.dateTime_create)).text}
                                                    </div>
                                                    {checkUserStatus(['gAdmin'], User?.status) && <div className="id">
                                                        <b>ID: </b>{history?._id}
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className='text'>{parceHistory(history)}</div>
                                            <div className="count">{index + 1} из {isHistoryCount}</div>
                                        </div>
                                    ))}

                                    {isHistory.length < isHistoryCount && <>

                                        {isLoadingNext.status && <Loading module progress={isLoadingNext.progress} >{isLoadingNext.msg}</Loading>}
                                        {/* <ButtonX full onUse={(e) => { onClick.addLoad(e) }}>Загрузить ещё...</ButtonX> */}
                                    </>}
                                </>
                                :
                                <AlertX type="info" icon={<IconX name="info" />}>История этой заявки пуста</AlertX>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default RequestsViewHistory